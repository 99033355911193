<template>
  <div>
    <!--<mct-topo-lista icone="fa-flag" :titulo="titulo" :subtitulo="`Listagem de ${titulo}`" v-show="!showModal"></mct-topo-lista>    -->
    <mct-filtro-lista :columns="columns" :query="query" @fetchIndexData="fetchIndexData"></mct-filtro-lista>        
    <slot>
        <mct-grid-lista :id="id" :columns="columns" :filters="filters" :data="data" :query="query" :source="source" @toggleOrder="toggleOrder" :showModal="showModal" @setDescricao="setDescricao"></mct-grid-lista>            
    </slot>
    <mct-rodape-lista :data="data" :query="query" @navigate="navigate" @fetchIndexData="fetchIndexData"></mct-rodape-lista>        
  </div>
</template>
<script>
  import Vue from 'vue'
  import axios from '@/http'

  import MctTopoLista from './MctTopoLista.vue'
  import MctRodapeLista from './MctRodapeLista.vue'
  import MctGridLista from './MctGridLista.vue'
  import MctFiltroLista from './MctFiltroLista2.vue'

  export default {
    name: 'MctListaPadrao',
    components: {
        'MctTopoLista': MctTopoLista,
        'MctRodapeLista': MctRodapeLista,
        'MctFiltroLista': MctFiltroLista,
        'MctGridLista': MctGridLista
    },        
    props: ['source', 'titulo', 'showModal'],
    data() {
      return {
        id: '',
        data: {},
        columns: {},
        filters: {},
        query: {
          page: 1,
          column: this.id,
          direction: 'asc',
          per_page: 10,
          search_column: this.id,
          search_operator: 'equal',
          search_input: ''
        }
      }
    },
    created() {
      this.fetchIndexData()
    },
    updated(){
      //this.fetchIndexData()      
    },
    mounted(){
      //this.fetchIndexData()      
    },
    watch: {
        source: function () {
          this.query.column = ''
          this.query.search_column = ''
          this.query.page = 1
          this.query.direction = 'asc'
          this.query.per_page = 10
          this.query.search_operator = 'equal'
          this.query.search_input = ''

          this.fetchIndexData()      
        }
    },    
    methods: {
      navigate (page) {
            this.query.page = page
            this.fetchIndexData()
        },
      toggleOrder(column) {
        if(column === this.query.column) {
          if(this.query.direction === 'desc') {
            this.query.direction = 'asc'
          } else {
            this.query.direction = 'desc'
          }
        } else {
          this.query.column = column
          this.query.direction = 'asc'
        }

        this.fetchIndexData()
      },
      fetchIndexData() {
        var vm = this
        //axios.get(`${this.source}?column=${this.query.column}&direction=${this.query.direction}&page=${this.query.page}&per_page=${this.query.per_page}&search_column=${this.query.search_column}&search_operator=${this.query.search_operator}&search_input=${this.query.search_input}`)
        axios.get(this.source,{params:  {
                column: this.query.column,
                direction: this.query.direction,
                page: this.query.page,
                per_page: this.query.per_page,
                search_column: this.query.search_column,
                search_operator: this.query.search_operator,
                search_input: this.query.search_input,
                columns: this.filtros
            },
            paramsSerializer: function (params) {
              return jQuery.param(params)
            }
            })
            .then(function(response) {
            Vue.set(vm.$data, 'data', response.data.data)
            Vue.set(vm.$data, 'columns', response.data.columns)
            Vue.set(vm.$data, 'filters', response.data.filters)
            Vue.set(vm.$data, 'id', response.data.primay_key)
            vm.$emit('setData', vm.$data.data, vm.$data.columns, vm.$data.query)
          })
          .catch(function(response) {
            //console.log(response.message)
          })
      },
      setDescricao (data ){
          this.$emit('setDescricao', data)
      }  
    },
    computed: {
        filtros() {
            var self = this
            if (this.filters != null && Object.keys(this.filters).length > 0){
              return this.filters.filter(function(item) {                
                  return typeof item.filtro != 'undefined' && (item.filtro == true || item.filtro == 'true')            
              })
            }else{
              return this.filters
            }
        }
    }
  }
</script>