<template>
    <nav class="navbar navbar-toggleable-md navbar-light bg-faded form-inline">
        <div class="input-group mb-2 mr-sm-2 mb-sm-0 row" style="max-height: 31px;">
            <div class="input-group-prepend">
                <label class="input-group-text" for="inputGroupSelect01">Mostrando registro(s) <b>&nbsp;{{data.from}}&nbsp;</b> até <b>&nbsp;{{data.to}}&nbsp;</b> de <b>&nbsp;{{data.total}}&nbsp;</b> registro(s).</label>
            </div>
            <div class="input-group-prepend">
                <label class="input-group-text" for="inputGroupSelect01">Registro(s) por página:</label>
            </div>
            <input type="text" v-model="query.per_page"
                class="input-group-prepend col-sm-2"
                style="max-width: 55px;"
                @keyup.enter="getData($event)">
            <!--<div class="input-group-addon">Página atual: <b>&nbsp;{{data.current_page}}&nbsp;</b></div>-->
            <mct-paginacao :lista="data" @navigate="navigate_pag"></mct-paginacao>           
        </div>
    </nav>
</template>
<script>
import MctPaginacao from './MctPaginacao.vue'

export default {    
    components: {
      'MctPaginacao': MctPaginacao
      },
    props: ['data', 'query'],
    methods: {
        getData(ev) {
            ev.preventDefault()
            this.$emit('getData')    
        },
        navigate_pag(page) {
            this.$emit('navigate', page)    
        }    
    }
}
</script>

