<template>
    <div class="mct-grid">
        <div class="mct-grid-scroll">
            <div v-if="(typeof data.data == 'undefined') || data.data.length == 0"
                class="animated fadeIn d-flex flex-row justify-content-center align-items-center align-self-center w-100 h-100" style="min-height: 400px;">
                <div class="d-flex flex-row align-items-center align-self-center bg-info">
                    <div class="p-4">
                        <span class="fa-stack fa-lg"><i class="fa fa-square fa-stack-2x text-white"></i><i class="fa fa-info fa-stack-1x fa-inverse text-info"></i></span>
                    </div>
                    <div class="py-2 pr-4">
                        <p><strong style="font-size: 16px;" class="text-white">Nenhum registro foi encontrado com as opções informadas.</strong></p>
                        <p><small style="font-size: 14px;" class="text-light">Nenhum registro foi localizado...</small></p>
                    </div>
                </div>
            </div>
            <div v-else>
                <table class="animated fadeIn table table-striped table-sm table-hover mb-0">
                    <thead>
                        <tr>
                            <th :style="showModal ? 'width: 25px!important;' : 'width: 75px!important;'"></th>
                            <th v-for="(column, index) in columns" @click="toggleOrder($event,column.nome)" :key="index" v-if="column.visivel_lista !== false && column.visivel_lista !== 'false'"
                                :style="column.tamanhoCol > 0 ? `width: ${column.tamanhoCol}px!important; cursor: pointer;` : 'cursor: pointer;'">
                                <div class="d-flex flex-row justify-content-between align-items-center">
                                    <span>{{column.titulo}}</span>
                                    <span class="dv-table-column">
                                        <i class="fa fa-long-arrow-up text-muted" aria-hidden="true" v-if="column.nome === query.column && query.direction === 'desc'"></i>
                                        <i class="fa fa-long-arrow-down text-muted" aria-hidden="true" v-else-if="column.nome === query.column && query.direction === 'asc'"></i>
                                        <i class="fa fa-exchange fa-rotate-90 text-muted" aria-hidden="true" v-else></i>
                                    </span>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th class="py-0 my-0 pb-1"></th>
                            <th class="py-0 my-0 pb-1" v-for="(column, index) in columns" :key="index" v-if="column.visivel_lista !== false && column.visivel_lista !== 'false'">
                                <mct-filtro-campo :column="column" :source="source" v-if="column.filtro || column.searchable"/>
                            </th>                        
                        </tr> 
                    </thead>
                    <tbody>
                        <tr v-for="(row,index) in data.data" :key="index" @click.prevent.stop="setDescricao($event,row)">
                            <td>
                                <div class="btn-group" role="group" aria-label="Ações">
                                    <button type="button" class="btn btn-primary btn-sm" v-if="!showModal" @click.prevent.stop="redirect($event,`${$route.fullPath.replace('/lista','')}/${getIndexes(row)}/editar`)" title="Editar"><i class="fa fa-pencil text-white"></i></button>
                                    <button type="button" class="btn btn-danger btn-sm" v-if="!showModal" @click.prevent.stop="redirect($event,`${$route.fullPath.replace('/lista','')}/${getIndexes(row)}/deletar`)" title="Deletar"><i class="fa fa-times text-white"></i></button>
                                    <button type="button" class="btn btn-danger btn-sm" v-if="!showModal && showButtonCancelar" @click="redirect($event,`${$route.fullPath.replace('/lista','')}/${getIndexes(row)}/cancelar`)" title="Cancelar"><i class="fa fa-ban text-white"></i></button>
                                    <button type="button" class="btn btn-secondary btn-sm" v-if="!showModal" @click.prevent.stop="redirect($event,`${$route.fullPath.replace('/lista','')}/${getIndexes(row)}/visualizar`)" title="Visualizar"><i class="fa fa-search text-white"></i></button>
                                    <button type="button" class="btn btn-info btn-sm" v-if="showModal" @click.prevent.stop="setDescricao($event,row)" title="Selecionar"><i class="fa fa-hand-pointer-o text-white"></i></button>
                                </div>
                            </td>
                            <td v-for="(column, key) in columns" :key="key" v-if="column.visivel_lista !== false && column.visivel_lista !== 'false' && column.tipo != 'checkbox' && column.tipo != 'badges'">
                                <span v-if="column.tipo !== 'switch'">
                                    <i class="float-left pt-1 pr-1 text-secondary fa" :class="column.tipo == 'currency' ? 'fa-usd' : column.tipo == 'date' ? 'fa-calendar' : column.mascara == 'telefone' ? 'fa-phone' : column.mascara == 'celular' ? 'fa-mobile' : column.mascara == 'cep' ? 'fa-map-marker' : ''" aria-hidden="true"></i>
                                        {{column.origem && column.origem != null && row[column.origem_campo] && row[column.origem_campo] != null ? typeof column.origem_alias != 'undefined' && column.origem_alias != null && column.origem_alias ? row[column.origem_alias] : row[column.origem_campo] : 
                                        column.subtab && row[column.subtab] != null ? row[column.subtab][column.nome] : 
                                        column.subtabela && row[column.subtabela] != null ? row[column.subtabela][column.subtabelacampo] :
                                        column.data ? retornaData(column.data, row[column.nome]) : 
                                        column.tipo == 'checkbox' && row[column.nome] == '1' ? 'S' :
                                        column.tipo == 'checkbox' && row[column.nome] == '0' ? 'N' :
                                        column.tipo == 'radio' && row[column.nome] == '1' ? 'S' :
                                        column.tipo == 'radio' && row[column.nome] == '0' ? 'N' :
                                        column.tipo == 'date' ? row[column.nome] != null && typeof row[column.nome] != 'undefined' ? $options.filters.data(row[column.nome]) : '' : 
                                        column.tipo == 'currency' ? $options.filters.moeda(row[column.nome]) : 
                                        row[column.nome] }} <small class="text-muted" v-if="column.colunaSec && column.colunaSec !== ''">{{row[column.colunaSec]}}</small>
                                </span>
                                <span v-else-if="column.tipo == 'switch'">
                                    <b-form-checkbox
                                        value="1"
                                        unchecked-value="0"
                                        v-model="row[column.nome]" 
                                        switch
                                        disabled
                                        size="lg"
                                        :class="row[column.nome] == 1 ? 'text-success' : 'text-danger'">
                                    </b-form-checkbox>
                                </span>
                                <slot :name="column.nome" :column="column" :row="row"></slot>

                            </td>
                            <td class="text-center" v-else-if="column.visivel_lista !== false && column.visivel_lista !== 'false' && column.tipo == 'checkbox'">
                                <span class="badge badge-success p-1 pl-2 pr-2 m-0" v-if="row[column.nome] === 1">ATIVO</span>
                                <span class="badge badge-danger p-1 pl-2 pr-2 m-0" v-if="row[column.nome] === 0">INATIVO</span>
                            </td>
                            <td class="text-center" v-else-if="column.visivel_lista !== false && column.visivel_lista !== 'false' && column.tipo == 'badges'">
                                <span :class="getClass(column.nome,row[column.nome])" class="badge badge-secondary p-1 pl-2 pr-2 m-0">{{row[column.nome]}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>   
</template>
<script>
import MctFiltroCampo from '@/components/comum/MctFiltroCampo.vue'

export default {
    name: 'MctGridLista',
    props: ['id', 'columns', 'data', 'query', 'source', 'showModal', 'classCampo', 'showButtonCancelar'],
    components: {
        MctFiltroCampo
    },
    methods: {  
        indexWhere(array, conditionFn) {
            let item = array.find(conditionFn)
            return array.indexOf(item)
        },
        retornaData ( items, value ){
            if ((value === null) || (value === ''))
                return '';
            let index = this.indexWhere(items, item => String(item.value) === String(value))
            return items[index]['text']
        },        
        toggleOrder(ev, column) {
            ev.preventDefault()
            this.$emit('toggleOrder', column)    
        },
        setDescricao (ev, data){
            ev.preventDefault()
            if (this.showModal){
                this.$emit('setDescricao', data)
            }
        },
        getVisivel (key) {
            for (var index in this.columns) {
                if (this.columns[index]['nome'] == key){
                    if (typeof this.columns[index]['visivel_lista'] == 'undefined'){
                        return true                         
                    }else{
                        return this.columns[index]['visivel_lista']  
                    }        
                }
            }
            return false
        },
        getClass (campo,valor){
            if(this.classCampo && typeof this.classCampo.filter !== 'undefined'){
                var ret = this.classCampo.filter(el => {
                    return el.campo == campo && el.valor == valor;
                })
                if (typeof ret[0] !== 'undefined'){
                    return ret[0].class
                }else{
                    return ''
                }
            }            
        },
        redirect (ev, link) {
            ev.preventDefault();
            this.$loader.show()
            this.$router.push(link)
        },
        getIndexes (row) {
            var indexes = ''
            if (typeof this.id === 'object'){
               for (var index in this.id) {               
                    if (indexes === ''){
                        indexes = row[this.id[0]]
                    } else {    
                        indexes = indexes + '/' + row[this.id[index]]
                    }
               }
            } else {
                indexes = row[this.id]   
            }
            return indexes
        }               
    }, 
}
</script>