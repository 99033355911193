<template>
    <ul class="pagination justify-content-end input-group-addon paginacao m-0 p-0">
        <li class="page-item" :class="{ disabled: lista.current_page == 1 }">
        <a class="page-link" href="#" @click="nextPrev($event, lista.current_page-1)" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
            <span class="sr-only">Previous</span>
        </a>
        </li>
        
        <li class="page-item" v-for="(page,index) in pages" :key="index" track-by="$index" :class="{ active: lista.current_page == page}">
            <span class="page-link" v-if="page == '...'">{{ page }}</span>
            <a class="page-link" href="#" v-if="page != '...'" @click="navigate($event, page)">{{ page }}</a>
        </li>

        <li class="page-item" :class="{ disabled: lista.current_page == lista.last_page }">        
        <a class="page-link" href="#" @click="nextPrev($event, lista.current_page+1)" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
            <span class="sr-only">Next</span>
        </a>
        </li>
    </ul>
</template>

<script>    
    export default {
        props: ['lista'],  

        data () {
            return {
                pages: []    
            }
        },

        created: function () {
        },
        methods: {
            nextPrev (ev, page) {
                if (page == 0 || page == this.lista.last_page+1){
                    return;
                }
                this.navigate(ev, page)
            },
            navigate (ev, page) {
                ev.preventDefault()
                this.$emit('navigate', page)
            },

            generatePagesArray: function(currentPage, collectionLength, rowsPerPage, paginationRange)
            {
                var pages = [];
                var totalPages = Math.ceil(collectionLength / rowsPerPage);
                var halfWay = Math.ceil(paginationRange / 2);
                var position;

                if (currentPage <= halfWay) {
                    position = 'start';
                } else if (totalPages - halfWay < currentPage) {
                    position = 'end';
                } else {
                    position = 'middle';
                }

                var ellipsesNeeded = paginationRange < totalPages;
                var i = 1;
                while (i <= totalPages && i <= paginationRange) {
                    var pageNumber = this.calculatePageNumber(i, currentPage, paginationRange, totalPages);
                    var openingEllipsesNeeded = (i === 2 && (position === 'middle' || position === 'end'));
                    var closingEllipsesNeeded = (i === paginationRange - 1 && (position === 'middle' || position === 'start'));
                    if (ellipsesNeeded && (openingEllipsesNeeded || closingEllipsesNeeded)) {
                        pages.push('...');
                    } else {
                        pages.push(pageNumber);
                    }
                    i ++;
                }
                return pages;
            },

            calculatePageNumber: function(i, currentPage, paginationRange, totalPages)
            {
                var halfWay = Math.ceil(paginationRange/2);
                if (i === paginationRange) {
                    return totalPages;
                } else if (i === 1) {
                    return i;
                } else if (paginationRange < totalPages) {
                    if (totalPages - halfWay < currentPage) {
                    return totalPages - paginationRange + i;
                } else if (halfWay < currentPage) {
                    return currentPage - halfWay + i;
                } else {
                    return i;
                }
                } else {
                    return i;
                }
            }            
        },

        watch: {
            lista () {
                let s = this.lista
                this.pages = this.generatePagesArray(s.current_page, s.total, s.per_page, 10)
            }
        }
    }

</script>