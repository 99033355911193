<template>
    <input v-if="column.filtro || column.searchable" type="text" style="width: 100%; display: block" @keydown.enter.prevent="onFilter(column.nome)" v-model="valor"/> 
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
    name: 'MCtFiltroCampo',
    props: ['column','source'],
    data() {
      return {
          valor: ''       
      }
    },
    methods: {
        onFilter(campo) {
            this.$store.dispatch('Padrao/PADRAO_FILTRO_CAMPO', [this.column, this.source, this.valor]);
        }
    }
}
</script>

