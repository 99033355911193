<template>  
    <div id="mct_filtros" class="mct-form-filter">                 
        <div v-for="(column, index) in filters" :key="index" class="input-group mb-2 mr-sm-2 mb-sm-0" v-if="column.filtro != 'undefined' && (column.filtro == true || column.filtro == 'true')">
            <div class="input-group-prepend">
                <label class="input-group-text" for="inputGroupSelect01" style="min-width: 150px;">{{ column.titulo }}</label>
            </div>            
            
            <select id="filtrotipo" class="form-control mx-sm col-sm-2" v-model="column.filter_operator"
                v-if="!(typeof column.modo != 'undefined' && column.modo == 'readonly')">
                <option v-for="(value, key) in operators" :value="key" :key="key">{{value}}</option>
            </select>

            <input :type="column.tipo" 
                class="form-control mx-sm" 
                v-if="column.tipo in {'text':1, 'number':1, 'date':1, 'password':1, 'email':1}"
                :class="[column.tamanho > 0 ? `col-sm-${column.tamanho}` : 'col-md-auto']"
                placeholder="Pesquisa"
                v-model="column.filter_input"
                @keyup.enter="getData($event)">

            <div class="input-group-prepend" v-if="(typeof column.filtro_intervalo != 'undefined' && (column.filtro_intervalo == true || column.filtro_intervalo == 'true'))">
                <label class="input-group-text" for="inputGroupSelect01" style="min-width: 40px;">ATÉ</label>
            </div>  

            <input :type="column.tipo" 
                class="form-control mx-sm" 
                v-if="column.tipo in {'text':1, 'number':1, 'date':1, 'password':1, 'email':1} && (typeof column.filtro_intervalo != 'undefined' && (column.filtro_intervalo == true || column.filtro_intervalo == 'true'))"
                :class="[column.tamanho > 0 ? `col-sm-${column.tamanho}` : 'col-md-auto']"
                placeholder="Pesquisa"
                v-model="column.filter_input2"
                @keyup.enter="getData($event)">            
        </div>

        <div class="h-divider clear"></div>
        
        <button type="button" class="btn btn-secondary btn-sm" aria-label="Left Align" @click="getData($event)">
            <span class="fa fa-search" aria-hidden="true"></span> Buscar 
        </button> 
        <button type="button" class="btn btn-danger btn-sm" aria-label="Left Align">
            <span class="fa fa-eraser" aria-hidden="true"></span> Limpar 
        </button>                 
    </div>
</template>
<script>

export default {
    name: 'MCtFiltroLista2',
    components: {        

    },    
    props: ['columns', 'filters', 'query'],
    data() {
      return {
        operators: {
          equal: 'IGUAL A (=)',
          not_equal: 'DIFERENTE DE (<>)',
          less_than: 'MENOR QUE (<)',
          greater_than: 'MAIOR QUE (>)',
          less_than_or_equal_to: 'MENOR OU IGUAL A (<=)',
          greater_than_or_equal_to: 'MAIOR OU IGUAL A (>=)',
          in: 'DENTRO (C*C)',
          like: 'CONTÉM (*C*)',
          between: 'ENTRE (C...C)'
        }
      }
    },
    methods: {
        getData(ev) {
            ev.preventDefault()
            this.$emit('getData')   
        },
    }

}
</script>

